.creditsAlertText {
    color: #e91010;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
}

.creditsAlertEmail {
  color: #E91010;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: underline;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.creditsAlert {
  width: 100%;
  //height: 50px;
  background-color: #FFF1E8;
  padding: 12px 8px 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  gap: 4px;
}