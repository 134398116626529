.footer {
  padding: 30px 0px 0px 0px;

  &Links {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    padding: 0 0 30px;
    border-bottom: 1px solid rgba(179, 188, 201, 0.3);
    & li:not(:last-child) {
      padding-right: 25px;
      border-right: 1px solid rgba(179, 188, 201, 0.3);
    }

    & li {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      color: #717780;
      &:hover {
        color: #196faf;
      }
    }
  }
}

.copyright {
  display: block;
  padding: 40px 0px 0;
  font-size: 16px;
  font-weight: 500;
  color: #717780;
}

@media screen and (max-width: 768px) {
  .footer {
    &Links {
      gap: 20px;
      & li:not(:last-child) {
        padding-right: 20px;
      }
      & li {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
      }
    }
  }
  .copyright {
    padding-top: 30px;
    font-size: 14px;
  }
}

.footerContainer {
  margin: 0px;
}

.main-container {
  //padding: 0 20px;

  @media (min-width: 768px) {
    //padding: 0 150px;
    margin: 0 auto;

    max-width: 1300px;
  }
}