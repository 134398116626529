.mapHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    justify-content: start;
    margin-bottom: 40px;
  }

  &Title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;

    @media (min-width: 768px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
    }
  }
}
