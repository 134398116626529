.block {
  border: 1px solid #d2dbe8;
  
  @media print {
    border: none;
  }
}

.rentComps {
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px;
  gap: 14px;

  @media (min-width: 768px) {
    padding: 40px 0 24px;
  }

  @media print {
    padding: 0px 0 24px;
  }

  &Box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    justify-content: space-between;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    &Title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #2d3035;

      @media (min-width: 768px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 34px;
        color: #2d3035;
      }
    }
  }
}

.secondaryTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #2d3035;

  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #2d3035;
  }
}

.secondaryTitleNumber{
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #2d3035;

  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    color: #2d3035;
  }
}

// .tableCell {
//   min-width: 200px; /* Default width for table cells */
// }

// @media print and (orientation: portrait) {
//   .tableCell {
//     width: 100px; /* Width for table cells in portrait orientation during print */
//   }
// }