body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  position: relative;
}

.zeroBalanceBox1 {
  margin-top: 13px;
  width: 100%;
}

.zeroBalanceBox2 {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20000;
}

.enterpricePage {
  //padding: 40px 140px;
  padding: 0px 20px;
  @media (min-width: 768px) {
    //padding: 0 150px;
  }

  &RentLogo {
    padding-top: 70px;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      padding-top: 100px;
      margin-bottom: 40px;
    }

    @media print {
      padding-top: 30px;
    }
  }
}

.main-container {
  padding: 0 20px;

  @media (min-width: 1150px) {
    //padding: 0 150px;
    margin: 0 auto;

    width: 1100px;
  }

  @media (min-width: 1300px) {
    //padding: 0 150px;

    width: auto;
    max-width: 1250px;
    margin: 0 auto;
  }
}

.formContainer {
  border: 2px solid #d2d2d2;
  border-radius: 20px;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 44px 50px 0px 50px;
  }

  @media print {
    padding: 44px 10px 0;
  }
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input {
  font-family: "Roboto";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.wrapper {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  overflow: hidden;
  flex: 1 0 auto;
  width: 100%;
  position: relative;
}

.container {
  margin: 0 auto;
  width: 1140px;
  max-width: 94%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 0 0;
  img {
    width: 45px;
  }
  &Listing {
    padding: 150px 0 100px;
  }
}

.reCaptcha {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .reCaptcha {
    justify-content: center;
  }
}

// MAIN

// Form

.formWrapper {
  margin-top: -90px;
  transition: all 0.4s ease;
  position: relative;
  &Container {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 40px 50px 50px 50px;
    overflow: hidden;
  }
}

// Input

.formRequest {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 30px 40px;
  div:nth-child(2) {
    grid-row: 2;
    grid-column: 2;
  }
  div:nth-child(3) {
    grid-row: 2;
    grid-column: 3;
  }
  div:nth-child(5) {
    grid-row: 1;
    grid-column: 2;
  }
  div:nth-child(6) {
    grid-row: 1;
    grid-column: 3;
  }
  &Input {
    border-radius: 4px;
    border: 1px solid #b3bcc9;
    padding: 10px 14px 9px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #2d3035;
  }
  &InputStandard {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #b3bcc9;
    padding: 10px 14px 9px;
    margin: 0;
    position: relative;
    width: 100%;
    input {
      border: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #2d3035;
      padding-left: 18px;
      width: 100%;
    }
    .prefix {
      position: absolute;
      font-size: 16px;
      font-weight: 400;
      color: #717780;
    }
  }
  &Label {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #2d3035;

    @media (min-width: 768px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      color: #2d3035;
    }

    input:disabled {
      background-color: rgba(112, 116, 121, 0.05);
      cursor: not-allowed;
      color: #b3bcc9;
      border-color: rgba(112, 116, 121, 0.4);
      background-color: rgba(112, 116, 121, 0.05);
    }
  }
}

// Radio

.formRequestRadioItems {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}
.formRequestRadioItem {
  flex: 1 1 100%;
  padding: 7px 7px 7px 30px;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #2d3035;
  border-radius: 7px;
  border: 1px solid #b3bcc9;
  &Disabled {
    background-color: rgba(112, 116, 121, 0.05);
    cursor: not-allowed;
    color: #b3bcc9;
  }
}

.formRequestRadioTitle {
  font-size: 14px;
  font-weight: 500;
  color: #2d3035;
}

.formRequestRadioTitle-bath {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  &::before {
    position: absolute;
    content: " ";
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    left: 0px;
  }
}
.formRequestRadioTitle-bed {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  &::before {
    position: absolute;
    content: " ";
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    left: 0px;
  }
}
.formRequestRadioTitle-roommate {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  &::before {
    position: absolute;
    content: " ";
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    left: 0px;
  }
}

.formRequestRadioLabel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.formRequestRadioLabel::before,
.formRequestRadioLabel::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.formRequestRadioLabel::before {
  left: 7px;
  border: 1px solid #b3bcc9;
  box-sizing: border-box;
}
.formRequestRadioLabel::after {
  left: 7px;
  display: none;
}
.formRequestRadioLabel::before {
  border-radius: 50%;
}
input[type="radio"]:checked + label::before {
  left: 7px;
  background-color: #5796d2;
  border: none;
  box-shadow: none;
}

input[type="radio"]:checked + label::after {
  display: block;
}

.inputChecked {
  color: #ffffff;
  border-radius: 7px;
  background: #5796d2;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  border: 1px solid #5796d2;
}

.formRequestRadioContainer .formRequestRadioItem {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

// Submit

.formRequestSubmit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  &Total {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  &Title {
    font-size: 14px;
    font-weight: 400;
    color: #2d3035;
  }
  &Price {
    font-size: 22px;
    font-weight: 500;
    color: #2d3035;
  }
  &Btn {
    font-family: "Roboto";
    flex: 1;
    max-width: 200px;
    padding: 14px 14px;
    border-radius: 4px;
    background: #ef6f22;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 156.25%;
    color: #fff;
    border: none;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
    }
    &Reset {
      max-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      font-family: "Roboto";
      flex: 1;
      padding: 14px 14px;
      border-radius: 4px;
      background: #ef6f22;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 156.25%;
      color: #fff;
      border: none;
      cursor: pointer;
      svg {
        transition: transform ease-in-out 0.6s;
      }
      &:hover {
        opacity: 0.7;
        svg {
          -ms-transform: rotate(180deg); /* IE 9 */
          -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
          transform: rotate(180deg);
        }
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

//
.result {
  border-top: 1px solid #d2dbe8;
  margin-top: 50px;
  padding-top: 40px;
  &Title {
    color: #2d3035;
    font-size: 36px;
    font-weight: 500;
    line-height: 155%;
    margin-bottom: 24px;
  }
  &Main {
    display: flex;
    justify-content: space-between;
    &Data {
      max-width: 355px;
    }
    &Meter {
      align-self: flex-start;
      width: 55%;
    }
  }
}

.resultSummary {
  &Content {
    text-align: left;
  }
  &Title {
    padding: 12px 16px;
    border-radius: 12px;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    width: max-content;
    &Red {
      background-color: #ffeded;
    }
    &Green {
      background-color: #e6f4ff;
    }
    &Blue {
      background-color: #e3fae1;
    }
  }
  &Description {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #2d3035;
    margin-top: 15px;
    white-space: pre-wrap;
  }
  &Address {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #2d3035;
    margin-top: 25px;
    svg {
      min-width: 28px;
    }
  }
  &Data {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    color: #717780;
    margin-top: 15px;
    max-width: 449px;
  }
  &Buttons {
    grid-row: 2 / 3;
    grid-column: 2;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
  }
  &Button {
    padding: 8px 12px;
    border-radius: 3px;
    background: #5796d2;
    color: #fff;
    text-align: center;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    border: 1.5px solid #5796d2;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &Share {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    padding: 7.5px 12px;
    border-radius: 3px;
    border: 1.5px solid #5796d2;
    color: #5796d2;
    text-align: center;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    min-width: 205px;
    height: 41px;
    background-color: #fff;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.resultListing {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  &Map {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

#map {
  padding-top: 48%;
}

// .indicator {
//   display: flex;
//   justify-content: space-between;
//   column-gap: 50px;
//   border: 1px solid green;
//   &-colors {
//     align-self: flex-start;
//     display: flex;
//     flex-direction: column;
//     row-gap: 12px;
//     border: 1px solid #d2dbe8;
//     border-radius: 8px;
//     padding: 12px;
//     margin-bottom: 90px;
//   }
//   &-color {
//     display: flex;
//     align-items: center;
//     flex-wrap: nowrap;
//     white-space: nowrap;
//     column-gap: 8px;
//     color: #2d3035;
//     font-size: 12px;
//     font-weight: 400;
//     &-title {
//       display: flex;
//       flex-direction: column;
//       row-gap: 8px;
//     }
//     &-budget {
//       color: #000;
//       font-size: 14px;
//       font-weight: 400;
//     }
//     &-desc {
//       color: #717780;
//       font-size: 12px;
//       font-weight: 400;
//       line-height: normal;
//       white-space: nowrap;
//     }
//     .color-box {
//       min-width: 20px;
//       width: 20px;
//       height: 20px;
//     }
//   }
//   &-color:nth-child(4) .color-box {
//     background: #c7e1c2;
//   }
//   &-color:nth-child(3) .color-box {
//     border: 1px solid #e3e7ee;
//     background: #fff;
//   }
//   &-color:nth-child(2) .color-box {
//     background: #fbd9c5;
//   }
//   &-box {
//     align-self: flex-end;
//     position: relative;
//     max-width: 245px;
//     margin-left: 30px;
//     .min {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       bottom: 24px;
//       left: 34px;
//     }
//     .max {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       bottom: 24px;
//       right: 34px;
//     }
//     .percentile-25 {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       top: 73px;
//       left: 48px;
//     }
//     .percentile-75 {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       top: 73px;
//       right: 48px;
//     }
//     .average-rent {
//       position: absolute;
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 1.78;
//       bottom: calc(100% + 25px);
//       left: 50%;
//       transform: translateX(-50%);
//       &::before {
//         //content: 'Average rent in this area';
//         font-weight: 400;
//         font-size: 12px;
//         line-height: 1.1;
//         text-align: center;
//         color: #717780;
//         position: absolute;
//         bottom: 100%;
//         left: -7.5px;
//         width: 70px;
//       }
//       &::after {
//         content: '';
//         position: absolute;
//         width: 2px;
//         height: 40px;
//         background: #196faf;
//         left: calc(50% - 1px);
//         bottom: -49px;
//         border-radius: 1px;
//       }
//     }
//     .your-rent {
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 1.78;
//       position: absolute;
//       left: -20px;
//       bottom: calc(100% - 20px);
//       &::before {
//         //content: 'Your Budget';
//         font-weight: 400;
//         font-size: 12px;
//         line-height: 1.1;
//         text-align: center;
//         color: #717780;
//         position: absolute;
//         bottom: 100%;
//         left: -7.5px;
//         width: 69px;
//       }
//     }
//     .rent-arrow {
//       position: absolute;
//       transform-origin: right;
//       bottom: 36px;
//       left: 21px;
//       transform: rotate(12deg);
//       transition: all 1s ease;
//       width: 103px;
//       height: 1px;
//       svg {
//         position: absolute;
//         top: -7px;
//         left: 7px;
//       }
//     }
//   }
// }

.table {
  border: none;
  &Type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 32px;
    margin: 16px 0;
    &Item {
      color: #2d3035;
      border-bottom: 3px solid #2d3035;
      text-align: center;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      border: unset;
      background: unset;
      border-bottom: 3px solid #fff;
      padding: 7px 0 14px 0;
      cursor: pointer;
      &:hover {
        filter: brightness(2);
      }
      &Special {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 4px;
        color: #ef6f22;
        border-bottom: 3px solid #ef6f22;
        text-align: center;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        border: unset;
        background: unset;
        border-bottom: 3px solid #fff;
        padding: 7px 0 14px 0;
        cursor: pointer;
        &:hover {
          filter: brightness(1.1);
        }
      }
    }
    &Active {
      border-bottom: 3px solid #2d3035;
      &Special {
        border-bottom: 3px solid #ef6f22;
      }
    }
  }
  &Empty {
    text-align: center;
  }
  &Container {
    box-shadow: none !important;
    border: none !important;
  }
  &Head {
    background: #f3f6f9;
    border: none;
    box-shadow: none;
    &Cell {
      border: none !important;
      color: #2d3035 !important;
      font-family: "Roboto" !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      line-height: 25px !important;
      padding: 16px 10px !important;
      white-space: nowrap;
    }
  }
  &Row {
    height: 70px;
  }

  &Row:nth-child(even) {
    background: #f8fafd;
  }
  &Cell {
    border: none !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    padding: 6px 10px !important;
    &Container {
      display: flex;
      column-gap: 10px;
    }
    &Content {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }
    &Icons {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
    &Icon {
      display: flex;
      border-radius: 4px;
      border: 1px solid #d2dbe8;
      background: #fff;
      padding: 6px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      column-gap: 4px;
      white-space: nowrap;
      &Blue {
        border: 1px solid #5796d2;
      }
    }
    &Contact {
      font-family: "Roboto";
      border-radius: 4px;
      border: 1px solid #d2dbe8;
      background: #fff;
      color: #d2dbe8;
      font-size: 10px;
      font-weight: 500;
      padding: 7px 10px;
      cursor: not-allowed;
    }
  }
}

.resultsLegend {
  position: absolute;
  left: 20px;
  /* right: 0; */
  bottom: 20px;
  width: 240px;
  border-radius: 8px;
  border: 1px solid #b3bcc9;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  row-gap: 6px;
  color: #2d3035;
  font-size: 14px;
  font-weight: 400;
  & > div:first-child {
    font-weight: 500;
    line-height: 25px;
  }
}

.resultsLegendColors {
  position: absolute;
  right: 20px;
  /* right: 0; */
  bottom: 20px;
  width: 240px;
  border-radius: 8px;
  border: 1px solid #b3bcc9;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  row-gap: 6px;
  color: #2d3035;
  font-size: 14px;
  font-weight: 400;
  &Title,
  &SubTitle {
    font-weight: 500;
    line-height: 25px;
  }
  &Near,
  &Below,
  &Special {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 12px;
  }
  &Near {
    .resultsLegendBox {
      border: 1px solid #196faf;
      background-color: #5796d2;
    }
  }
  &Below {
    .resultsLegendBox {
      border: 1px solid #609854;
      background-color: #74b566;
    }
  }
  &Special {
    .resultsLegendBox {
      border: 1px solid #ef6f22;
      background-color: #ef6f22;
    }
  }
}
.resultsLegendBox {
  width: 20px;
  height: 20px;
}

.resultListingMap {
  position: relative;
}

.resultListingContainer {
  position: relative;
}

.rent-line {
  display: block;
  opacity: 1;
  position: absolute;
  height: 2px;
  width: 40px;
  background: #196faf;
  border-radius: 1px;
  left: -38px;
  top: 1px;
  transition: all 1s;
}

.resultMsg {
  padding: 40px 24px;
  position: absolute;
  top: 35%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  color: #2d3035;
  z-index: 1;
  background: white;
  border-radius: 15px;
  text-align: center;
  &Btn {
    width: 100%;
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    background: #5796d2;
    color: #fff;
    margin-top: 20px;
    padding: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.resultGoBack {
  position: absolute;
  left: 10px;
  bottom: 30px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  font-family: "Roboto";
  flex: 1;
  padding: 8px 12px;
  border-radius: 4px;
  background: #5796d2;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
  border: none;
  z-index: 1000;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.resultNoPanorama {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
  z-index: 1000;
  text-align: center;
}

.tooltip {
  position: relative;
  left: 6px;
  bottom: 6px;
  &Text {
    display: none;
    position: absolute;
    width: 120px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    color: #2d3035;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 8px 0px #00000026;
    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }
  }
  &:hover {
    .tooltipText {
      display: block;
    }
  }
}

// MOBILE

@media screen and (max-width: 1023px) {
  .result {
    &Main {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      row-gap: 24px;
      &Meter {
        align-self: unset;
        width: unset;
      }
    }
    &Summary {
      &Title {
        margin: 0 auto;
      }
      &Content {
        text-align: center;
      }
      &Data {
        max-width: unset;
      }
      &Address {
        justify-content: center;
      }
      &Buttons {
        justify-content: center;
      }
    }
  }
  .indicator {
    column-gap: 100px;
  }
}
@media screen and (max-width: 950px) {
  .formRequest {
    grid-template-columns: repeat(2, 45%);
    grid-template-rows: repeat(2, auto);
    div:nth-child(2) {
      grid-row: 3;
      grid-column: 1;
    }
    div:nth-child(3) {
      grid-row: 3;
      grid-column: 2;
    }
    div:nth-child(6) {
      grid-row: 2;
      grid-column: 2;
    }
    div:nth-child(5) {
      grid-row: 2;
      grid-column: 1;
    }
  }
  .formRequestSubmit {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  #map {
    height: 250px !important;
  }
  .formWrapper {
    margin-top: 0px;
    &Container {
      padding: 30px 18px;
      overflow: hidden;
    }
  }
  .formRequest {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(1, auto);
    gap: 30px 40px;
    div:nth-child(2) {
      grid-row: unset;
      grid-column: unset;
    }
    div:nth-child(3) {
      grid-row: unset;
      grid-column: unset;
    }

    div:nth-child(4) {
      grid-row: 2;
    }
    div:nth-child(5) {
      grid-row: 3;
      grid-column: unset;
    }
    div:nth-child(6) {
      grid-row: 4;
      grid-column: unset;
    }
  }
  .formRequestSubmit {
    flex-direction: column;
    row-gap: 30px;
    &Total {
      text-align: center;
    }
    &Btn {
      width: 100%;
      width: 210px;
      padding: 14px 22px;
      font-size: 14px;
      font-weight: 700;
      &Reset {
        max-width: unset;
        width: 100%;
        padding: 14px 22px;
        font-size: 14px;
        font-weight: 700;
      }
      &Recalculate {
        max-width: unset;
        width: 100%;
        padding: 14px 22px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .result {
    margin-top: 30px;
    padding-top: 30px;
    &Title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 13px;
    }
  }
  .resultSummary {
    &Content {
      text-align: center;
    }
    &Title {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      padding: 6px 12px;
    }
    &Description {
      font-size: 14px;
      line-height: 23px;
      margin-top: 13px;
    }
    &Address {
      font-size: 14px;
      line-height: 23px;
      margin-top: 13px;
    }
    &Data {
      font-size: 14px;
      line-height: 23px;
      margin-top: 13px;
    }
    &Buttons {
      width: 100%;
      grid-row: 3;
      grid-column: 1;
      margin-top: 30px;
      &Mobile {
        display: flex;
      }
      &Pc {
        display: none;
      }
    }
    &Button {
      width: 100%;
    }
    &Share {
      width: 100%;
    }
  }
  .resultListing {
    display: flex;
    flex-direction: column;
    row-gap: unset;
    margin-top: 50px;
    &Map {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .resultNoPanorama {
    top: 40%;
  }
  // .indicator {
  //   margin-top: 80px;
  //   flex-direction: column;
  //   row-gap: 24px;
  //   align-items: center;
  //   border: 1px solid green;
  //   &-colors {
  //     display: flex;
  //     flex-direction: row;
  //     flex-wrap: wrap;
  //     column-gap: 15px;
  //     max-width: 300px;
  //     margin-bottom: unset;
  //     align-self: unset;
  //     border: none;
  //   }
  //   &-color {
  //     column-gap: 10px;
  //     font-size: 10px;
  //     flex: 1;
  //     justify-content: center;
  //     &-title {
  //       flex: 1 1 100%;
  //       align-items: center;
  //     }
  //     .color-box {
  //       min-width: 20px;
  //       width: 20px;
  //       height: 20px;
  //     }
  //   }
  //   &-box {
  //     margin-left: 0px;
  //     align-self: unset;
  //   }
  // }
  .table {
    border: none;
    &Type {
      &Item {
        font-size: 12px;
        padding: 7px 0 14px 0;

        &Special {
          font-size: 12px;
        }
      }
    }
    &Container {
      box-shadow: none !important;
      border: none !important;
    }
    &Head {
      background: #f3f6f9;
      border: none;
      box-shadow: none;
      &Cell {
        border: none !important;
        color: #2d3035 !important;
        font-family: "Roboto" !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 25px !important;
        padding: 12px 10px !important;
      }
    }
    &Row:nth-child(even) {
      background: #f8fafd;
    }
    &Cell {
      border: none !important;
      font-family: "Roboto" !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 16px !important;
      letter-spacing: normal !important;
      padding: 4px 10px !important;
      &Container {
        display: flex;
        column-gap: 10px;
      }
      &Content {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }
      &Icons {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
      &Icon {
        display: flex;
        border-radius: 4px;
        border: 1px solid #d2dbe8;
        background: #fff;
        padding: 6px;
      }
    }
  }
  .resultsLegend {
    display: none;
  }
  .resultsLegendColors {
    position: static;
    &Title,
    &SubTitle {
      display: none;
    }
    width: unset;
    border: none;
    background: unset;
    flex-direction: row;
    padding: 16px 0;
    column-gap: 25px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    &Near,
    &Below {
      column-gap: 6px;
    }
  }
  .resultMsg {
    max-width: 500px;
    width: 90%;
    padding: 30px 20px;
    font-size: 20px;
    line-height: normal;
    &Btn {
      font-size: 14px;
      padding: 10px;
    }
  }
  .resultGoBack {
    font-size: 0px;
    column-gap: 0px;
    bottom: 85px;
  }
  .mobile {
    &ViewListings {
      border: none;
      width: 100%;
      text-align: center;
      background: #5796d2;
      padding: 10px 12px;
      border-radius: 3px;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      margin-top: 16px;
    }
  }
}

@media screen and (min-width: 769px) {
  .resultSummary {
    &Buttons {
      &Mobile {
        display: none;
      }
    }
  }
}

// Animation

.animated-green {
  path:nth-child(4) {
    filter: drop-shadow(0 0 8px #7bc560);
    fill: #c2f3b0;
    transition: all 0.8s ease;
  }
  path:nth-child(n + 15):nth-child(-n + 20) {
    stroke: #2b9604;
    transition: stroke 0.8s ease-in-out;
  }
}
.animated-pink {
  path:nth-child(3) {
    filter: drop-shadow(0 0 8px #e58b50);
    fill: #fdbc92;
    transition: all 0.8s ease;
  }
  path:nth-child(n + 5):nth-child(-n + 10) {
    stroke: #fe7b26;
    transition: stroke 0.8s ease-in-out;
  }
}

// .indicator {
//   //display: flex;
//   //justify-content: space-between;
//   //column-gap: 50px;
//   border: 1px solid yellow;
//   &-colors {
//     align-self: flex-start;
//     display: flex;
//     flex-direction: column;
//     row-gap: 12px;
//     border: 1px solid #d2dbe8;
//     border-radius: 8px;
//     padding: 12px;
//     margin-bottom: 90px;
//   }
//   &-color {
//     display: flex;
//     align-items: center;
//     flex-wrap: nowrap;
//     white-space: nowrap;
//     column-gap: 8px;
//     color: #2d3035;
//     font-size: 12px;
//     font-weight: 400;
//     &-title {
//       display: flex;
//       flex-direction: column;
//       row-gap: 8px;
//     }
//     &-budget {
//       color: #000;
//       font-size: 14px;
//       font-weight: 400;
//     }
//     &-desc {
//       color: #717780;
//       font-size: 12px;
//       font-weight: 400;
//       line-height: normal;
//       white-space: nowrap;
//     }
//     .color-box {
//       min-width: 20px;
//       width: 20px;
//       height: 20px;
//     }
//   }
//   &-color:nth-child(4) .color-box {
//     background: #c7e1c2;
//   }
//   &-color:nth-child(3) .color-box {
//     border: 1px solid #e3e7ee;
//     background: #fff;
//   }
//   &-color:nth-child(2) .color-box {
//     background: #fbd9c5;
//   }
//   &-box {
//     align-self: flex-end;
//     position: relative;
//     max-width: 245px;
//     margin-left: 30px;
//     .min {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       bottom: 24px;
//       left: 34px;
//     }
//     .max {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       bottom: 24px;
//       right: 34px;
//     }
//     .percentile-25 {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       top: 73px;
//       left: 48px;
//     }
//     .percentile-75 {
//       font-weight: 400;
//       font-size: 10px;
//       line-height: 12px;
//       color: #2d3035;
//       position: absolute;
//       top: 73px;
//       right: 48px;
//     }
//     .average-rent {
//       position: absolute;
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 1.78;
//       bottom: calc(100% + 25px);
//       left: 50%;
//       transform: translateX(-50%);
//       &::before {
//         content: 'Average rent in this area';
//         font-weight: 400;
//         font-size: 12px;
//         line-height: 1.1;
//         text-align: center;
//         color: #717780;
//         position: absolute;
//         bottom: 100%;
//         left: -7.5px;
//         width: 70px;
//       }
//       &::after {
//         content: '';
//         position: absolute;
//         width: 2px;
//         height: 40px;
//         background: #196faf;
//         left: calc(50% - 1px);
//         bottom: -49px;
//         border-radius: 1px;
//       }
//     }
//     .your-rent {
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 1.78;
//       position: absolute;
//       left: -20px;
//       bottom: calc(100% - 20px);
//       &::before {
//         //content: 'Your Budget';
//         font-weight: 400;
//         font-size: 12px;
//         line-height: 1.1;
//         text-align: center;
//         color: #717780;
//         position: absolute;
//         bottom: 100%;
//         left: -7.5px;
//         width: 69px;
//       }
//     }
//     .rent-arrow {
//       position: absolute;
//       transform-origin: right;
//       bottom: 36px;
//       left: 21px;
//       transform: rotate(12deg);
//       transition: all 1s ease;
//       width: 103px;
//       height: 1px;
//       svg {
//         position: absolute;
//         top: -7px;
//         left: 7px;
//       }
//     }
//   }
// }

@media print {
  .none {
    display: none !important;
  }

  @page {
    margin: 0;
    padding: 0;
  }

  .print-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }

  .page-break {
    page-break-before: always;
  }

  .avoid-break {
    page-break-inside: avoid;
  }

  .section {
    page-break-after: always;
  }
}

@media print and (orientation: landscape) {
  .page-break-land {
    page-break-before: always;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .page-break-land {
      page-break-before: always;
    }
  }
}

@media print and (orientation: portrait) {
  .page-break-port {
    page-break-before: always;
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.rentCompsContainer {
  @media print {
    width: max-content;
  }
}

.check {
  color: blueviolet;
}

.safari .check {
  color: red;
}

.chrome .check {
  color: blue;
}

@supports (-webkit-touch-callout: none) {
  .check {
    color: blue;
  }
}

@supports (-webkit-touch-callout: none) and (not (translate3d(0, 0, 0))) {
  .your-class {
    color: green; /* Replace with your desired styles */
  }
}