.indicator {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  &-colors {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border: 1px solid #d2dbe8;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 90px;
  }
  &-color {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    column-gap: 8px;
    color: #2d3035;
    font-size: 12px;
    font-weight: 400;
    &-title {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    &-budget {
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
    &-desc {
      color: #717780;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
    .color-box {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
  &-color:nth-child(4) .color-box {
    background: #c7e1c2;
  }
  &-color:nth-child(3) .color-box {
    border: 1px solid #e3e7ee;
    background: #fff;
  }
  &-color:nth-child(2) .color-box {
    background: #fbd9c5;
  }
  &-box {
    align-self: flex-end;
    position: relative;
    max-width: 245px;
    //margin-left: 30px;
    .min {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #2d3035;
      position: absolute;
      bottom: 24px;
      left: 34px;
    }
    .max {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #2d3035;
      position: absolute;
      bottom: 24px;
      right: 34px;
    }
    .percentile-25 {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #2d3035;
      position: absolute;
      top: 73px;
      left: 48px;
    }
    .percentile-75 {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #2d3035;
      position: absolute;
      top: 73px;
      right: 48px;
    }
    .average-rent {
      position: absolute;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.78;
      bottom: calc(100% + 25px);
      left: 50%;
      transform: translateX(-50%);
      &::before {
        content: "Average rent in this area";
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        text-align: center;
        color: #717780;
        position: absolute;
        bottom: 100%;
        left: -7.5px;
        width: 70px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 40px;
        background: #196faf;
        left: calc(50% - 1px);
        bottom: -49px;
        border-radius: 1px;
      }
    }
    .your-rent {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.78;
      position: absolute;
      left: -20px;
      bottom: calc(100% - 20px);
      &::before {
        //content: 'Your Budget';
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        text-align: center;
        color: #717780;
        position: absolute;
        bottom: 100%;
        left: -7.5px;
        width: 69px;
      }
    }
    .rent-arrow {
      position: absolute;
      transform-origin: right;
      bottom: 36px;
      left: 21px;
      transform: rotate(12deg);
      transition: all 1s ease;
      width: 103px;
      height: 1px;
      svg {
        position: absolute;
        top: -7px;
        left: 7px;
      }
    }
  }
}
