.viewContainer {
  .block {
    border: 1px solid #d2dbe8;
  }

  .content {
    padding: 24px 0;

    position: relative;

    @media (min-width: 768px) {
      padding: 24px 0 40px;
    }

    @media print {
      padding: 24px 0 24px;
    }

    display: flex;
    flex-direction: column;

    gap: 12px;

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      align-items: center;

      @media (min-width: 768px) {
        gap: 38px;
        align-items: start;
      }

      @media (min-width: 1100px) {
        gap: 26px;
        align-items: start;
      }

      @media print {
        height: max-content;
        gap: 38px;
        align-items: start;
      }

      &TitleBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 22px;

        @media (min-width: 768px) {
          flex-direction: column;
          gap: 2px;
          //gap: 38px;
        }

        @media (min-width: 1100px) {
          flex-direction: row;
          gap: 38px;
        }

        @media print {
          flex-direction: column;
          gap: 2px;
        }
      }
    }

    // @media print {
    //   flex-direction: row;
    // }

    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @media print {
      display: flex;
      flex-direction: row;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;

    //display: inline-block;
    position: relative;

    @media (min-width: 768px) {
      font-size: 28px;
      font-weight: 500;
      line-height: 48px;
      color: #2d3035;
      text-align: start;
    }

    @media (min-width: 1100px) {
      font-size: 36px;
      font-weight: 500;
      line-height: 56px;
      color: #2d3035;
      text-align: start;
    }

    @media print {
      font-size: 28px;
      font-weight: 500;
      line-height: 48px;
      color: #2d3035;
      text-align: start;
    }

    .trademark {
      font-size: 0.5em; /* Smaller size for TM */
      vertical-align: top; /* Align TM to the top of QuickView */
      position: absolute;
      top: 0.2em;
      right: -1.8em; /* Adjust as needed to position it correctly */

      font-size: 10px;
      font-weight: 500;
      line-height: 12px;

      @media (min-width: 768px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #2d3035;
        text-align: start;
      }

      @media print {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #2d3035;
        text-align: start;
      }
    }
  }

  .titleShort {
    @media (min-width: 768px) and (max-width: 1099px) {
      max-width: 130px;
    }

    @media print {
      max-width: 130px;
    }
  }

  .addressBlock {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: start;
    }
  }

  .address {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #2d3035;

    @media (min-width: 768px) {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }

    @media print {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #2d3035;
    text-align: center;
    margin-bottom: "26px";
    align-items: start;
    width: 80%;

    @media (min-width: 768px) {
      text-align: start;
      width: 320px;
      font-size: 18px;
    }

    @media print {
      text-align: start;
      width: 320px;
      font-size: 18px;
    }
  }

  .buttonBox {
    display: flex;
    width: 100%;
    justify-content: center;

    position: absolute;
    bottom: 35px;

    @media (min-width: 768px) {
      position: static;
      justify-content: start;
    }

    .viewBtn {
      background-color: #196faf;
      padding: 8px 60px;

      font-size: 14px;
      font-weight: 600;
      line-height: 25px;

      text-transform: none;
    }
  }

  .meterBox {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .metrics {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 768px) {
      gap: 40px;
    }
  }
}

.trademark-block {
  @media (min-width: 768px) {
    width: 170px;
  }
}

.analysis {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #2d3035;
}

@media print {
  .result-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .result-grid-item {
    width: 100%;
  }
}


