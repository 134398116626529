body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

.enterpricePage {
  //padding: 40px;
}

.formContainer {
  border: 2px solid #d2d2d2;
  //padding: 44px 50px 44px 50px;
  border-radius: 20px;

  .formContent {
    padding: 30px 0 40px;
  }

  @media print {
    //width: max-content;
  }
}

.item {
  &__Label {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    margin-bottom: 10px;
    color: #2d3035;
  }
}
.autocomplete {
  &Container {
    display: flex;
    column-gap: 5px;
  }
  &Input {
    border-radius: 4px;
    border: 1px solid #b3bcc9;
    color: #2d3035;
    font-size: 15px;
    font-weight: 400;
    line-height: 156.25%;
    padding: 10px 14px;
    width: 100%;
  }
}
:global(.pac-container) {
  border-radius: 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: 10px;
}
:global(.pac-item) {
  padding: 5px;
}
:global(.pac-item:first-child) {
  border: none;
}
.resetButton {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #b3bcc9;
  color: #b3bcc9;
  padding: 0px 5px;
  cursor: pointer;
  transition: all 0.3s;
  svg {
    width: 15px;
    height: 15px;
    transition: all ease-in-out 0.6s;
    path {
      transition: all 0.3s;
      fill: #b3bcc9;
    }
  }
  &:hover {
    border: 1px solid #2d3035;
    color: #2d3035;
    svg {
      -ms-transform: rotate(180deg); /* IE 9 */
      -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
      transform: rotate(180deg);
      path {
        fill: #2d3035;
      }
    }
  }
}

.autocomplete {
  &Mobile {
    &Container {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .formRequestInputContainerGap {
    margin-bottom: -28px;
  }
  .autocomplete {
    &Mobile {
      &Container {
        display: flex;
        justify-content: space-between;
      }
      &Status {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
      }
      &Btn {
        display: flex;
        align-items: center;
        column-gap: 4px;
        border: none;
        background: none;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: right;
        color: #196faf;
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.7;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.input_page_container {
  max-width: 715px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px 96px 40px 96px;

  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #303134;
    margin-bottom: 14px;
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #707479;
    margin: 0;
  }

  .radio_group_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .input_label {
    display: flex;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #303134;
    margin-bottom: 14px;
    margin-top: 30px;
  }

  .buildingtype_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .buildingtype_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .buildingtype_item_radio {
    display: flex;
    margin: 0;
    width: 136px;
    justify-content: center;
    margin-top: 23px;
  }

  .button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    button {
      background: #ef6f22;
      border-radius: 4px;
      border: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      padding: 14px 42px;
      margin-top: 40px;
      margin-bottom: 6px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #f5a16f;
      margin: 0;
    }

    p {
      width: 300px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #707479;
      margin: 20px 0 0 0;

      a {
        font-family: "Roboto";
        font-weight: 500;
        color: #707479;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .input_page_container {
    padding: 30px 20px 30px 20px;

    h2 {
      font-size: 20px;
    }

    .buildingtype_item {
      img {
        height: 70px;
      }
    }

    .button_container {
      button {
        font-size: 14px;
        padding: 12px 34px;
        margin-top: 20px;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .input_page_container {
    padding: 30px 16px 30px 16px;

    .button_container {
      button {
        margin-top: 10px;
      }
    }
  }
}

.properties-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(3, auto);
  }

  @media print {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media print {
  .properties-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

.sectionType {
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #2d3035;

    @media (min-width: 768px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      color: #2d3035;
    }
  }
}
