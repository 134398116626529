.resultContainer {
  padding: 8px 20px;
  border: 1px solid #d2dbe8;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.resultText {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #717780;
}

.resultValue {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}
