.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: white;
  backdrop-filter: blur(5px);
  border-bottom: 2px solid #f3f6f9;
  padding: 10px 20px;
  // @media (min-width: 1200px) {
  //   padding: 10px 100px;
  // }
  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nav {
  &-links {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #717780;
  }
  &-button {
    padding: 9px 13px;
    border-radius: 3px;
    background: #74b566;
    color: #ffffff;
    &:hover {
      opacity: 0.7;
    }
  }
}

// BURGER

@media (min-width: 768px) {
  .nav {
    display: flex;
    align-items: center;
  }
  .nav-links {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .nav-links li:not(:last-child) {
    margin-right: 50px;
  }
  .burger {
    display: none;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 9px 0;
    &Container {
      .logo {
        img {
          //width: 193px;
        }
      }
    }
  }
  .nav {
    justify-content: space-between;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50px; /* Adjust according to your layout */
    left: 0;
    background: white;
    border-bottom: 2px solid #f3f6f9;
    z-index: 1;
    padding: 10px 0;
  }
  .nav-links.active {
    display: flex;
  }
  .nav-links li {
    margin: 10px 0;
  }
  .nav-button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #717780;
    background: none;
    padding: 0;
  }
  .burger {
    display: block;
    cursor: pointer;
  }
  .burger div {
    width: 25px;
    height: 3px;
    background-color: #b3bcc9;
    border-radius: 10px;
    margin: 5px;
    transition: transform 0.4s ease-in-out;
  }
  .burger.active .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .burger.active .line2 {
    opacity: 0;
  }
  .burger.active .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

.svg-type {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  will-change: transform;
  -webkit-transform: translateZ(0);
}