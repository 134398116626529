.titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &Title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #2d3035;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 34px;
      font-weight: 600;
      color: #2d3035;
      text-align: center;
    }
  }
}
